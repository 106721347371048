<script>
	import Header from '$lib/components/Header.svelte';
	import Footer from '$lib/components/Footer.svelte';
	import GdprBanner from '$lib/components/GdprBanner.svelte';
	import GoogleAnalytics from '$lib/components/google-analytics/GoogleAnalytics.svelte';
</script>

<Header />
<main>
	<slot />
</main>
<Footer />
<GoogleAnalytics />
<GdprBanner />
